import React from "react";

const Botones = ({
  usuario,
  puntaje,
  checked,
  setChecked,
  setPuntaje,
  setTotalPasos,
}) => {
  const printButtons = () => {
    let buttons = [];
    for (let index = 0; index <= 10; index++) {
      buttons = [
        ...buttons,
        <React.Fragment>
          <li
            className={`puntaje ${index < 6 ? "mobile" : ""} ${
              index === puntaje && checked ? "activo" : ""
            }`}
            style={
              index === 0
                ? { borderRadius: "5px 0 0 5px" }
                : index === 10
                ? { borderRadius: "0 5px 5px 0" }
                : null
            }
            onMouseEnter={() => hoverPuntaje(index)}
            onMouseLeave={() => hoverPuntaje(index)}
            onClick={(e) => seleccionarPuntaje(e, index)}
          >
            {index}
          </li>
        </React.Fragment>,
      ];
    }
    return buttons;
  };
  const hoverPuntaje = (index) => {
    if (index > 0) {
      const puntajes = document.querySelectorAll(".puntajes li");
      puntajes[index - 1].classList.contains("hover")
        ? puntajes[index - 1].classList.remove("hover")
        : puntajes[index - 1].classList.add("hover");
    }
  };
  const seleccionarPuntaje = (e, index) => {
    e.target.classList.add("activo");
    const listado = document.querySelectorAll("#puntajes li");
    listado.forEach((element, i) => {
      element.classList.contains("activo") &&
        i !== index &&
        element.classList.remove("activo");
    });
    setChecked(true);
    setPuntaje(index);
    index <= 9 ? setTotalPasos(3) : setTotalPasos(2);
  };
  return (
    <>
      <div>
        <h5
          style={{
            fontSize: "16px",
            textAlign: "center",
            maxWidth: "350px",
            margin: "0 auto",
            marginBottom: "60px",
          }}
        >
          {usuario.nombre}, ¿Qué tan probable es que recomiendes Axon Training a
          un colega, familiar o amigo?
        </h5>
        <ul
          className="puntajes"
          id="puntajes"
          style={{
            listStyle: "none",
            paddingLeft: "0",
            display: "flex",
            flexWrap: "wrap",
            rowGap: "2rem",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {printButtons()}
        </ul>
      </div>
    </>
  );
};

export default Botones;
