import React from "react";

const FormPregunta = ({ pregunta, step, error, setError, datos, setDatos }) => {
  const { respuestaTexto, motivoPuntaje } = datos;

  const handleChange = (respuesta) => {
    const key = step === 2 ? "respuestaTexto" : "motivoPuntaje";
    setDatos({ ...datos, [key]: respuesta });
    setError(false);
  };
  return (
    <>
      <h5
        style={{
          fontSize: "16px",
          textAlign: "center",
          maxWidth: "350px",
          margin: "0 auto",
        }}
      >
        {pregunta}
      </h5>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        <textarea
          value={step === 2 ? respuestaTexto : motivoPuntaje}
          style={{
            border: "2px solid #2A75D5",
            borderRadius: "8px",
            resize: "none",
            width: "350px",
            height: "110px",
            padding: "15px 20px",
            fontSize: "16px",
          }}
          placeholder="Escribe tu respuesta aquí..."
          onChange={(e) => handleChange(e.target.value)}
        ></textarea>
      </div>
      {error && (
        <p style={{ marginTop: "20px", color: "red", textAlign: "center" }}>
          Todos los campos son obligatorios
        </p>
      )}
    </>
  );
};

export default FormPregunta;
