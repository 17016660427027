import React from "react";

const BannerCompletado = () => {
  return (
    <div
      style={{
        textAlign: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "0 20px",
      }}
    >
      <div>
        <img
          src="https://s3.us-east-2.amazonaws.com/checkout.axontraining.com/resultado/success.png"
          style={{ maxWidth: "92px", maxHeight: "92px" }}
          alt="logo axon"
        />
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}>¡Muchas gracias!</p>
        <p style={{ maxWidth: "300px", margin: "0 auto" }}>
          Tus comentarios nos ayudan a brindarte un mejor servicio
        </p>
      </div>
      <div style={{ diplay: "flex", justifyContent: "center" }}>
        <a
          href="https://campus.axontraining.com/"
          className="btn"
          style={{
            background: "#2A75D5",
            padding: "10px 100px",
            border: "none",
            borderRadius: "7px",
            color: "#fff",
            margin: "20px 0",
            width: window.innerWidth > 768 ? "350px" : "100%",
          }}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          Ir al campus
        </a>
      </div>
    </div>
  );
};

export default BannerCompletado;
