import React, { Component } from "react";
import Encuesta from "./Pasos/Encuesta";
export default class Survey extends Component {
  render() {
    return (
      <div style={{backgroundColor: "#f7f7f7", height: "100vh"}}>
        <div style={{maxWidth: "650px", padding: "0 20px", margin: "0 auto", width: "100%"}}>
            <Encuesta token={this.props.match.params.token} />
        </div>
      </div>
    );
  }
}
