import Axios from "axios";
import React, { useEffect, useState } from "react";
import BannerCompletado from "./BannerCompletado";
import FormPregunta from "./FormPregunta";
import Botones from "./Botones";

const Encuesta = ({ token }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState();
  const [step, setStep] = useState(1);
  const [checked, setChecked] = useState();
  const [puntaje, setPuntaje] = useState(0);
  const [completado, setCompletado] = useState(false);
  const [totalPasos, setTotalPasos] = useState(2);
  const [datos, setDatos] = useState({
    respuestaTexto: "",
    motivoPuntaje: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    const consultarAPI = async () => {
      try {
        //Agarro los datos del token desde la API.
        const res = await Axios.get(
          `https://api.axontraining.com/master/surveys`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        //Acá manejo si ya hizo el primer paso antes

        if (res.data.type === "completada") {
          setResponse(res.data);
          setIsLoading(false);
          setCompletado(true);
        } else {
          setResponse(res.data);
          setIsLoading(false);
          setStep(1);
        }
      } catch (e) {
        //Si hay error, devuelvo el status.
        setResponse(null);
        setIsLoading(false);
      }
    };
    consultarAPI();
  }, []);

  const siguientePaso = () => {
    step < 3 && setStep(step + 1);
  };

  const pasoAnterior = () => {
    step > 0 && setStep(step - 1);
  };

  const errores = () => {
    const { respuestaTexto, motivoPuntaje } = datos;
    return (
      respuestaTexto.trim() === "" ||
      (puntaje < 10 && motivoPuntaje.trim() === "")
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const erroresCampos = errores();
    const { respuestaTexto, motivoPuntaje } = datos;
    if (erroresCampos) {
      setError(true);
      setIsLoading(false);
      return;
    }
    await Axios.post(
      "https://api.axontraining.com/master/surveys",
      {
        nps: puntaje,
        respuestaTexto,
        motivoPuntaje,
        npsFrom: "EMAIL",
      },
      {
        headers: {
          Authorization: token,
        },
      }
    ).then((res) => {
      if (res.data.type === "success") {
        setCompletado(true);
      } 
      setIsLoading(false);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        className="sombra"
        style={{
          borderRadius: "0 10px 10px 10px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="mt-5 spinner-grow text-primary" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        ) : (
          <>
            <div>
              <img
                src="https://axon-tienda.s3.us-east-2.amazonaws.com/logo-azul.png"
                className=" d-block"
                style={{
                  height: "auto",
                  margin: "3rem auto",
                  maxWidth: "180px",
                }}
                alt="logo"
              />
            </div>
            {!completado ? (
              <>
                <div style={{ padding: "0 20px" }}>
                  {step === 1 ? (
                    <Botones
                      usuario={response.usuario}
                      puntaje={puntaje}
                      checked={checked}
                      setChecked={setChecked}
                      setPuntaje={setPuntaje}
                      setTotalPasos={setTotalPasos}
                    />
                  ) : totalPasos === 3 ? (
                    <FormPregunta
                      pregunta={
                        step === 2
                          ? "¿Cuál es la principal causa de tu respuesta?"
                          : "¿Qué deberíamos hacer para que la próxima vez nos califiques mejor?"
                      }
                      step={step}
                      error={error}
                      datos={datos}
                      setDatos={setDatos}
                      setError={setError}
                    />
                  ) : (
                    <FormPregunta
                      pregunta="¿Cuál es la principal causa de tu respuesta?"
                      step={step}
                      error={error}
                      datos={datos}
                      setDatos={setDatos}
                      setError={setError}
                    />
                  )}
                </div>

                <div className="d-flex justify-content-center">
                  <div>
                    <button
                      disabled={!checked}
                      className="btn"
                      style={{
                        background: "#2A75D5",
                        padding: "10px 100px",
                        border: "none",
                        borderRadius: "7px",
                        color: "#fff",
                        margin: "20px 0",
                        width: window.innerWidth > 768 ? "350px" : "100%",
                      }}
                      onClick={
                        step === totalPasos
                          ? () => handleSubmit()
                          : () => siguientePaso()
                      }
                    >
                      {step !== totalPasos ? "Siguiente" : "Finalizar"}
                    </button>

                    <p
                      style={{
                        textAlign: "center",
                        color: "#0148A1",
                        cursor: "pointer",
                      }}
                      onClick={() => pasoAnterior()}
                    >
                      {step > 1 ? "Anterior" : ""}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <BannerCompletado />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Encuesta;
